<template>
  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
    <div class="small-box bg-purple pointer" v-on:click="$router.push('/tasks/funnel/call_center')">
      <div class="inner">
        <h3>{{ call_center_tasks_count + estimate_tasks_count }}</h3>
        <h5>Call-center / Оценка-продажа</h5>
      </div>
      <div class="icon">
        <i class="fas fa-headset"></i>
      </div>

      <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
        <i class="fa font-scale fa-arrow-circle-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'call-center-box',

  props: {
    call_center_tasks_count: {
      type: Number,
      required: true,
      default: 0,
    },
    estimate_tasks_count: {
      type: Number,
      required: true,
      default: 0,
    },
  }
}
</script>
